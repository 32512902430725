h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    margin-right: 1rem;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
  }
  
  .status-label {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    color: #fff;
    background-color: #6c757d;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
  }
  
  .status-label.success {
    background-color: #28a745;
  }
  
  .status-label.danger {
    background-color: #dc3545;
  }
  
  .status-label.warning {
    background-color: #ffc107;
  }

  .event-info {
    /* display: flex; */ /* これを削除 */
    align-items: center;
    margin-bottom: 2rem;
    position: relative; /* これを追加 */
  }
  
  .event-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 10px;
    /* margin-right: 1rem; */ /* これを削除 */
  }
  
  /* 以下を追加 */
  .event-info .status-label {
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 4px;
    margin-bottom: 4px;

}

.event-info .status-label {
    margin-right: 4px;
    margin-bottom: 4px;
  }

  .event-info .status-label-container {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  
  /* 既存のCSS */

/* 参加者・コメントリスト関連のスタイル */
.list-group {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 2rem;
  }
  
  .list-group-item {
    border: none !important;
    padding: 0.5rem 0;
    font-size: 1rem;
    line-height: 1.5;
    background-color: transparent !important;
    transition: all 0.3s ease-in-out;
  }
  
  .list-group-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .list-group-item + .list-group-item {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }  
  /* 参加者名・コメント者名を太字にする */
  .list-group-item .author {
    font-weight: bold;
  }
  
  /* コメント入力フォームのスタイル */
  .comment-form {
    display: flex;
    gap: 1rem;
  }
  
  .comment-form input[type="text"] {
    flex-grow: 1;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .comment-form button {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 0.25rem;
    color: #fff;
    cursor: pointer;
  }
  
.attendees-card,
.comments-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 2rem;
}

.attendees-card h2,
.comments-card h2 {
  margin-bottom: 0;
}

.comment-form {
  margin-top: 20px;
}

.participant-card img,
.participant-card i {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.participant-card i.fas.fa-user-circle {
  font-size: 32px;
}
.participant-card a {
  text-decoration: none;
  color: inherit;
}

.participants-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.participants-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 16px;
}

.participant-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.participant-card img,
.participant-card .fas {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}

.participant-card span {
  margin-top: 8px;
  font-size: 14px;
  color: #333;
  text-decoration: none;
}

/* スマホ表示時のスタイル */
@media (max-width: 768px) {
  .event-info {
    display: block; /* グリッドを解除してブロック表示に */
  }

  .info-card,
  .attendees-card,
  .comments-card {
    width: 100%; /* カードの幅を100%に */
    margin-bottom: 20px; /* 必要に応じてカード間のマージンを調整 */
  }
}
  .event-detail-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  .event-image {
    max-width: 100%;
    height: auto;
  }
  .status-label-container {
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
  }
  .status-label {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
  }
  .status-label.warning {
    background-color: #ffc107;
  }
  .status-label.danger {
    background-color: #dc3545;
  }
  .status-label.success {
    background-color: #28a745;
  }
  @media (max-width: 768px) {
    .event-detail-grid {
      grid-template-columns: 1fr;
    }
  }






