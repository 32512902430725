.card-link {
    text-decoration: none;
    color: inherit;
  }
  
  .card-link:hover {
    color: inherit;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    opacity: 1;
  }
  
  .restaurant-card:hover {
    box-shadow: none;
    opacity: 1;
  }
  
  .restaurant-card-img-wrapper {
    height: 200px;
    overflow: hidden;
    position: relative;
  }
  
  .restaurant-card-img-top {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  