.splash-screen {
    background-color: rgb(245, 245, 220);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .splash-title {
    color: #5c5c5c;
    font-size: 3rem;
    margin-bottom: 1rem;
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
  }
  
  .splash-subtitle {
    color: #8c8c8c;
    font-size: 1.5rem;
    margin-top: 0;
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .splash-title.fade-out {
    animation: fadeOut 1s ease-out forwards;
  }
  
  .splash-subtitle.fade-out {
    animation: fadeOut 1s ease-out forwards;
  }
  
  /* ... 既存の @keyframes 定義 ... */
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeBackground {
    0% {
      background-color: rgba(255, 255, 255, 1); /* 初期状態の背景色（完全な白） */
    }
    100% {
      background-color: rgba(255, 255, 255, 0); /* 最終状態の背景色（完全に透明） */
    }
  }
  
  .fade-out {
    animation-name: fadeOut;
    animation-duration: 0.5s; /* フェードアウトのアニメーション時間 */
    animation-fill-mode: forwards;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  