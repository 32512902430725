.font-weight-bold {
    font-weight: bold;
  }

  .custom-switch .form-check-input {
    width: 45px;
    max-width: 100%;
    height: 22px;
    background-color: #bbbbbb;
    border-radius: 15px;
    border: none;
    transition: background-color 0.2s ease;
  }
  
  .custom-switch .form-check-input:checked {
    background-color: #FFA07A;
  }
  
  .custom-switch .form-check-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2);
  }
  
  .donation-amount-wrapper {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 10000ms, opacity 10000ms;
  }
  
  .donation-amount-active {
    max-height: 100px;
    opacity: 1;
  }

  .custom-button-decision {
    width: auto; /* ボタンの幅を自動に設定 */
    padding: 8px 12px; /* パディングを調整 */
  }
  
  /* スマホ用のスタイル */
@media (max-width: 768px) {
  .create-event-container {
    padding: 20px 0;
  }

  .event-form {
    width: 90%;
  }
}





