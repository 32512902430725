.custom-button-select {
    background-color: #FF7F50 !important;
    border-color: #FF7F50 !important;
  }
  
  .custom-button-select:hover {
    background-color: #FF6347 !important;
    border-color: #FF6347 !important;
  }
  
  .custom-button-select {
    margin-right: 0px;
  }

  .custom-button-detail {
    background-color: #FFA07A !important;
    border-color: #FFA07A !important;
    color: #ffffff !important;
  }
  .custom-button-detail:hover {
    background-color: #FF8C69 !important;
    border-color: #FF8C69 !important;
    color: #ffffff !important;
  }
  
  .custom-button-no {
    background-color: #B0B0B0 !important;
    border-color: #B0B0B0 !important;
    color: #ffffff !important;
  }
  .custom-button-no:hover {
    background-color: #A0A0A0 !important;
    border-color: #A0A0A0 !important;
    color: #ffffff !important;
  }
  
  .custom-button-decision {
    background-color: #373d7a !important;
    border-color: #2db3a3 !important;
    color: #ffffff !important;
  }
  .custom-button-decision:hover {
    background-color: #282f6b !important;
    border-color: #FF8C69 !important;
    color: #ffffff !important;
  }


  .button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .button-container .btn:first-child {
    margin-right: 4px;
  }
  

  nav ul li a {
    color: #000000; /* ここに変更したいフォントカラーを指定する */
  }
  
  body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  footer {
    position: auto;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    padding: 10px;
    text-align: center;
  }
  