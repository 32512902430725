.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex: 1;
}

footer {
  margin-top: auto;
}

.website-link a {
  color: #6c757d;
  font-weight: 600;
  text-decoration: none;
}

.website-link a:hover {
  text-decoration: underline;
}

@keyframes fadeBackground {
  from {
    background-color: transparent;
  }
  to {
    background-color: #fff;
  }
}

.fade-background {
  animation-name: fadeBackground;
  animation-duration: 5s; /* ここでアニメーションの長さを調整できます */
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

body.fadeIn {
  animation: fadeIn 0.5s ease-in;
}
